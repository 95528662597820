<template>
  <div class="mb-[20px]">
    <header class="flex items-center justify-between mb-[8px]">
      <p :class="{'font-medium': bold}">{{ groupName }}</p>
      <BaseElSwitch v-model="groupToggle" :inactive-text="`隱藏/顯示 ${switchName}`" @change="onGroupChange" />
    </header>

    <div class="toggle-list">
      <div v-for="option in syncOptions" :key="option.key" class="flex items-center justify-between">
        <p>{{ option.label }}</p>
        <p v-if="option.content" class="text-sm text-gray-60">{{ option.content }}</p>
        <BaseElSwitch v-else v-model="option.value" inactive-text="隱藏/顯示" @change="onChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, nextTick, watch } from 'vue'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'ToggleGroup',
  props: {
    groupName: String,
    switchName: String,
    options: { type: Array, default: () => [] },
    bold: { type: Boolean, default: false },
  },
  setup (props, { emit }) {
    const groupToggle = ref(true)
    const syncOptions = useVModel(props, 'options', emit)

    const onGroupChange = (toggle) => {
      if (!toggle) {
        for (const key in syncOptions.value) {
          syncOptions.value[key].value = false
        }
      }
    }

    const onChange = (toggle) => {
      if (toggle) groupToggle.value = true
    }

    watch(props.options, () => {
      let someTruly = false
      for (const item of syncOptions.value) {
        console.log(props.groupName, item.label, item.value)
        if (item.value) someTruly = true
      }
      if (someTruly) {
        groupToggle.value = true
      } else if (!someTruly) {
        groupToggle.value = false
      }
    })

    onMounted(async () => {
      let someTruly = false
      for (const item of syncOptions.value) {
        console.log(props.groupName, item.label, item.value)
        if (item.value) someTruly = true
      }
      if (someTruly) {
        groupToggle.value = true
      } else if (!someTruly) {
        groupToggle.value = false
      }
    })
    return { syncOptions, onGroupChange, groupToggle, onChange }
  },
})
</script>

<style lang="postcss" scoped>
.toggle-list {
  @apply pl-[20px] grid gap-[8px];
}
</style>
