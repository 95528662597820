<template>
  <GrayBlockContainer class="w-[560px]">
    <div>
      <ToggleGroup bold groupName="訂單資訊" switchName="訂單資訊" :options.sync="orderInfo" />
      <ToggleGroup bold groupName="詳細資訊" switchName="詳細資訊" :options.sync="detailInfo" />
      <ToggleGroup v-if="allowOtherReservation || allowOrderMorePeople" bold groupName="其他服務 (客人端不顯示此標題)" switchName="其他服務" :options.sync="otherInfo" />
      <ToggleGroup v-if="userInfoSettingFormData.length" bold groupName="其他會員資料 (自定義註冊欄位)" switchName="其他會員資料" :options.sync="userInfoSettingFormData" />
      <header class="flex items-center justify-between mb-[8px]">
        <p class="font-medium">{{ get(commentInfo,'label') }}</p>
        <BaseElSwitch v-model="commentInfo.value" :inactive-text="`隱藏/顯示 ${get(commentInfo,'label')}`" />
      </header>
    </div>
  </GrayBlockContainer>
</template>

<script>
import { defineComponent, computed, watch, ref, reactive, onBeforeMount, set } from 'vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import ToggleGroup from '@/components/ToggleGroup.vue'
import { get, filter, map } from 'lodash'
import { appointmentInfoConfigTypes } from '@/config/progressNotification'
import { useVModel } from '@vueuse/core'
import { GetUserInfoSetting } from '@/api/org/org'
import { FindReservationParameter } from '@/api/reservation'
import store from '@/store'
import { checkUserFeature } from '@/store/modules/permission'

export default defineComponent({
  name: 'ReservationNotificationContentTemplate',
  components: { GrayBlockContainer, ToggleGroup },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const syncData = useVModel(props, 'data', emit)
    const newData = reactive({

    })
    for (const key in appointmentInfoConfigTypes) {
      set(newData, key, {
        label: appointmentInfoConfigTypes[key],
        key,
        value: get(props, `data.${key}`),
      })
      // newData[key] = {
      //   label: appointmentInfoConfigTypes[key],
      //   key,
      //   value: get(props, `data.${key}`),
      // }
    }
    const contractPlan = computed(() => get(store.state.shop, 'shopConfig.ContractPlan'))
    // 是否顯示附加服務
    // const orderServiceAttachClientCheck = ref(false)
    // 單人多服務
    const allowOtherReservation = ref(false)
    // 多人單服務
    const allowOrderMorePeople = ref(false)
    // 子項目
    const userPlanFeature = computed(() => get(store.getters, 'userPlanFeature'))
    const userFeatures = computed(() => get(store.getters, 'userFeatures'))
    const checkAction = (action) => {
      return checkUserFeature(userPlanFeature.value, userFeatures.value, action)
    }
    const useSubService = computed(() => checkAction('admin.appointmentSubService.edit'))
    const useMemberAddress = computed(() => checkAction('adminView.member.findUserInfoMoreAddress'))
    const {
      showOrderCode,
      showMemberName,
      showAppointmentPeopleCount,
      showAppointmentStartDate,
      showUserAddress,
      showAppointmentOrderDepositStatus,
      showServiceName,
      showSubServiceName,
      showAppointmentStartTime,
      showAppointmentUnitName,
      showAppointmentResourceName,
      showAppointmentServiceAttach,
      showAppointmentUserComment,
      showUserName,
      showCustomFieldKeys,
      showAppointmentTotalBookingTime,
    } = newData

    const orderInfo = ref([
      showOrderCode,
      showMemberName,
      showAppointmentPeopleCount,
      showAppointmentStartDate,
      // showUserAddress,
      // showAppointmentOrderDepositStatus,
    ])
    const detailInfo = ref([
      showServiceName,
      // showSubServiceName,
      // showAppointmentStartTime,
      // showAppointmentUnitName,
      // showAppointmentResourceName,
      // showAppointmentServiceAttach,
    ])
    const otherInfo = ref([
      // showUserName,
      // { label: '其他服務', value: showServiceName.value, content: '比照詳細資訊的服務項目顯示狀態' },
      // { label: '子項目', value: showSubServiceName.value, content: '比照詳細資訊的子項目顯示狀態' },
      // { label: '預約時間', value: showAppointmentStartTime.value, content: '比照詳細資訊的預約時間顯示狀態' },
      // { label: '服務人員', value: showAppointmentUnitName.value, content: '比照詳細資訊的服務人員顯示狀態' },
      // { label: '服務設備', value: showAppointmentResourceName.value, content: '比照詳細資訊的服務設備顯示狀態' },
      // { label: '附加服務', value: showAppointmentServiceAttach.value, content: '比照詳細資訊的附加服務顯示狀態' },
    ])
    const appendItems = async () => {
      // orderInfo
      if (useMemberAddress.value) orderInfo.value.push(showUserAddress)
      if (get(contractPlan.value, 'useAppointmentDeposit')) orderInfo.value.push(showAppointmentOrderDepositStatus)
      // detailInfo
      if (useSubService.value) detailInfo.value.push(showSubServiceName)
      detailInfo.value.push(showAppointmentStartTime)
      detailInfo.value.push(showAppointmentTotalBookingTime)
      detailInfo.value.push(showAppointmentUnitName)
      if (get(contractPlan.value, 'useResource')) detailInfo.value.push(showAppointmentResourceName)
      detailInfo.value.push(showAppointmentServiceAttach)
      // otherInfo
      if (allowOrderMorePeople.value) otherInfo.value.push(showUserName)
      if (allowOtherReservation.value) {
        otherInfo.value.push({ label: '其他服務', value: showServiceName.value, content: '比照詳細資訊的服務項目顯示狀態' })
        if (useSubService.value) otherInfo.value.push({ label: '子項目', value: showSubServiceName.value, content: '比照詳細資訊的子項目顯示狀態' })
        otherInfo.value.push({ label: '預約時間', value: showAppointmentStartTime.value, content: '比照詳細資訊的預約時間顯示狀態' })
        otherInfo.value.push({ label: '預約時長', value: showAppointmentTotalBookingTime.value, content: '比照詳細資訊的預約時長顯示狀態' })
        otherInfo.value.push({ label: '服務人員', value: showAppointmentUnitName.value, content: '比照詳細資訊的服務人員顯示狀態' })
        if (get(contractPlan.value, 'useResource')) otherInfo.value.push({ label: '服務設備', value: showAppointmentResourceName.value, content: '比照詳細資訊的服務設備顯示狀態' })
        otherInfo.value.push({ label: '附加服務', value: showAppointmentServiceAttach.value, content: '比照詳細資訊的附加服務顯示狀態' })
      }
    }

    const commentInfo = ref(showAppointmentUserComment)

    watch(newData, (newVal) => {
      console.log('newData change')
      for (const obj in newVal) {
        set(syncData.value, newVal[obj].key, newVal[obj].value)
        // syncData.value[newVal[obj].key] = newVal[obj].value
      }
    }, { deep: true })

    const userInfoSettingFormData = ref([])
    const orgId = computed(() => get(store.getters, 'orgId'))
    const shopId = computed(() => get(store.getters, 'shop'))
    const getUserInfoSetting = async () => {
      const [res, err] = await GetUserInfoSetting({
        orgId: orgId.value,
      })

      if (err) {
        if (err.status === 404) return
        return this.$message.error(err)
      }
      // 取得自定義欄位
      if (res && res.fields) {
        userInfoSettingFormData.value = map(filter(get(res, 'fields'), item => !item.isBase),
          item =>
            (
              {
                key: item.key,
                label: item.name,
                value: get(showCustomFieldKeys, 'value').includes(item.key), // 是否顯示
              }
            ),
        )
      }
    }
    const findReservationParameter = async () => {
      const res = await FindReservationParameter({ shopId: shopId.value })
      // orderServiceAttachClientCheck.value = get(res, 'orderServiceAttachClientCheck')
      allowOtherReservation.value = get(res, 'allowOtherReservation')
      allowOrderMorePeople.value = get(res, 'allowOrderMorePeople')
    }
    watch(userInfoSettingFormData, (newVal) => {
      syncData.value.showCustomFieldKeys = map(filter(newVal, item => item.value), item => item.key)
    }, { deep: true })

    onBeforeMount(async () => {
      await getUserInfoSetting()
      await findReservationParameter()
      await appendItems()
    })
    return { orderInfo, detailInfo, otherInfo, commentInfo, get, userInfoSettingFormData, allowOrderMorePeople, allowOtherReservation, newData }
  },
})
</script>

<style lang="postcss" scoped>

</style>
