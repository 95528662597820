<template>
  <BaseElFormItem>
    <div class="container">
      <div class="flex items-center justify-end" style="margin-bottom: 8px">
        <div class="flex items-center" style="gap: 20px">
          <div>
            <BaseElButton class="move-layer-btn" type="text" @click="$emit('upLayer')">
              上移一層<i class="el-icon-top el-icon--right" />
            </BaseElButton>
            <BaseElButton class="move-layer-btn" type="text" @click="$emit('downLayer')">
              下移一層<i class="el-icon-bottom el-icon--right" />
            </BaseElButton>
          </div>
          <BaseElButton class="remove-btn" type="text" @click="$emit('remove')">
            刪除<i class="el-icon-delete-solid el-icon--right" />
          </BaseElButton>
        </div>
      </div>

      <div>
        <UploadButton
          :img="syncData.Image"
          min-height="150px"
          @change="imageChange"
        />
        <p class="text-gray-80 text-sm">{{ notice }}</p>
      </div>
      <ImageCropper
        v-if="showCropper"
        :image="imgHolder"
        :ratio="ratio"
        @close="showCropper = false"
        @uploaded="getCroppedImage"
      />
    </div>
  </BaseElFormItem>
</template>

<script>
import { defineComponent, ref } from 'vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'ImageBlockNoTitleEditBlock',
  components: { UploadButton, ImageCropper },
  props: {
    data: { type: Object, default: () => ({}) },
    ratio: {
      type: Array,
      default: () => [10, 10],
    },
    notice: {
      type: String,
      default: '圖片最大寬度為 2000 px',
    },
  },
  setup (props, { emit }) {
    const syncData = useVModel(props, 'data', emit)
    const imgHolder = ref(null)
    const showCropper = ref(false)

    const getCroppedImage = (e) => {
      syncData.value.Image = e
      showCropper.value = false
    }

    const imageChange = (img, level) => {
      imgHolder.value = img
      showCropper.value = true
    }

    return { syncData, getCroppedImage, imageChange, showCropper, imgHolder }
  },
})
</script>

  <style lang="postcss" scoped>
  .container {
    @apply bg-gray-20 rounded-[8px] px-[12px] py-[20px] max-w-[560px];
  }

  .move-layer-btn {
    @apply border-solid border-[1px] border-primary-100 py-[5px] px-[8px] text-sm;
  }

  .remove-btn {
    @apply border-solid border-[1px] border-danger py-[5px] px-[8px] text-sm text-danger;
  }
  </style>
